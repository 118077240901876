import Footer from "../commun/footer";
import Header from "../commun/header";

const Services = () => {
  // Liste des prestations avec titres, descriptions, et images
  const services = [
    {
      title: 'Rénovation intérieure',
      description:
        'Transformez votre intérieur avec nos services de rénovation. Nous offrons des solutions personnalisées pour améliorer votre espace de vie.',
      image: '/assets/renovationInterieur.jpg',
    },
    {
      title: 'Rénovation extérieure',
      description:
        'Améliorez l’apparence extérieure de votre maison avec nos services de rénovation extérieure. Des façades aux terrasses, nous avons tout couvert.',
      image: '/assets/renovationExt.jpg',
    },
    {
      title: 'Conseil en décoration',
      description:
        'Nos experts en décoration vous aideront à créer un espace harmonieux et esthétique qui reflète votre style personnel.',
      image: '/assets/decoration.webp',
    },
    {
      title: 'Suivi de chantier',
      description:
        'Assurez-vous que votre projet se déroule sans accroc avec notre service de suivi de chantier. Nous nous occupons de tout.',
      image: '/assets/suivideChantier.jpg',
    },
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <Header />
      <div className="container mx-auto p-4 flex-grow">
        <h2 className="text-3xl text-blue-600 mb-8 text-start">Nos Prestations</h2>
        <p className="text-start text-lg">
          Vous pouvez retrouver nos préstations que nous proposons ainsi que des exemples de nos réalisation éffectué
          
        </p>
        <p className="text-start mb-10 text-lg">
        Nous proposons divers services de rénovation adaptés à vos besoins :
        </p>
        <div className="space-y-16">
          {services.map((service, index) => (
            <div
              key={index}
              className={`flex flex-col md:flex-row items-center md:items-stretch md:justify-between ${
                index % 2 === 0 ? 'md:flex-row-reverse' : ''
              }`}
            >
              <div className="md:w-1/2 p-4">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-64 object-cover rounded-lg shadow-lg"
                />
              </div>
              <div className="md:w-1/2 p-4">
                <div className="bg-white shadow-lg rounded-lg p-6 h-full flex flex-col justify-center">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">{service.title}</h3>
                  <p className="text-gray-600">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
