import Footer from '../commun/footer';
import Header from '../commun/header';

function Home() {
  const renovations = [
    {
      title: 'Rénovation de Cuisine',
      description: 'Transformation complète de la cuisine avec des matériaux modernes et un éclairage amélioré.',
      image: '/assets/renovationCuisine.jpg',
    },
    {
      title: 'Salle de Bain Luxueuse',
      description: 'Rénovation avec installation de nouveaux équipements et finition en marbre.',
      image: '/assets/renovationSalledeBain.jpeg',
    },
    {
      title: 'Aménagement de Salon',
      description: 'Optimisation de l’espace avec des meubles sur mesure et un design contemporain.',
      image: '/assets/amenagementDeSalob.jpg',
    },
    // Ajoute plus de projets si nécessaire
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <Header />
      <div className="container mx-auto p-4 flex-grow mt-10">
        <h2 className="text-3xl text-blue-600 mb-4 text-start">Nos Réalisations</h2>
        <p className="text-start mb-8 text-lg">Découvrez nos projets récents dans divers secteurs :</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {renovations.map((project, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105">
              <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800">{project.title}</h3>
                <p className="mt-2 text-gray-600">{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
