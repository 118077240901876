import React from 'react';
import Footer from '../commun/footer';
import Header from '../commun/header';

function Contact() {
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <Header />
      <div className="container mx-auto p-4 flex-grow">
        <h2 className="text-3xl text-blue-600 mb-4 text-center">Contactez-nous</h2>
        <div className="bg-white shadow-md rounded-lg p-6">
          <p className="text-lg mb-2">Suivez-nous sur les réseaux sociaux ou contactez-nous directement :</p>
          <div className="mt-4">
            <p className="font-semibold">Email: <a href="mailto:pierre221997@gmail.com" className="text-blue-600">pierre221997@gmail.com</a></p>
            <p className="font-semibold">Téléphone: <a href="tel:0123456789" className="text-blue-600">06 11 28 60 49</a></p>
          </div>
        </div>
        
        <div className="bg-white shadow-md rounded-lg p-6 mt-8">
          <h3 className="text-2xl text-blue-600 mb-4">Prendre rendez-vous</h3>
          <form className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nom</label>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Téléphone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
