
import { Link } from 'react-router-dom';
export default function Header() {
    return (
        <header className="bg-primary p-4 w-full top-0">
            <div className="container mx-auto flex justify-between items-center">
                <h1 className="text-white text-2xl">Is Renovation</h1>
                <nav className="text-white">
                    <ul className="flex flex-row items-center space-x-6">
                        <li><Link to="/" className="hover:text-blue-300 transition-colors duration-200">Accueil</Link></li>
                        <li><Link to="/services" className="hover:text-blue-300 transition-colors duration-200">Prestations</Link></li>
                        <li><Link to="/contact" className="hover:text-blue-300 transition-colors duration-200">Contact</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}
